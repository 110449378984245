<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>List Stock Per Barang</strong>
              </h5>
            </template>
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row> -->
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="is_data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template> -->
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <modal-input
      :fileName="file_name"
      :option_role="option_role"
      :option_puskesmas="option_puskesmas"
      @alertFromChild="triggerAlert($event), getDatas()"
    /> -->
    <modal-edit
      :fileName="file_name"
      :data_edit="is_data"
      :option_role="option_role"
      :option_puskesmas="option_puskesmas"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
// import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    // ModalInput,
    ModalDelete,
    ModalEdit,
  },
  name: "stock",
  data() {
    return {
      file_name: "stock",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_gudang",
          label: "Gudang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_barang",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_batch",
          label: "Kode Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tahun_anggaran",
          label: "Th. Anggaran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_stock",
          label: "Qty Stock",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_beli",
          label: "Qty Beli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_harga_sub_format",
          label: "Total Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kadaluarsa",
          label: "Tanggal Kadaluarsa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-2 text-center",
        // },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      option_puskesmas: [],
      option_role: [
        { value: "admin", text: "admin" },
        { value: "gudang", text: "gudang" },
        { value: "kasir", text: "kasir" },
        { value: "dokter", text: "dokter" },
        { value: "bidan", text: "bidan" },
        { value: "perawat", text: "perawat" },
        { value: "sanitarian", text: "sanitarian" },
        { value: "nutrisionis", text: "nutrisionis" },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let x = {
        ms_gudang_id: this.$store.state.gudang_id,
        ms_barang_id: this.$store.state.barang_id
      }
      let res = await vm.$axios.post(`/${vm.file_name}/list`, x);
      // console.log(vm.file_name, res);

      vm.items = res.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.id = x.user_id;
        x.kadaluarsa = this.$moment(x.tanggal_kadaluarsa).format("DD MMMM YYYY")
        x.total_harga_sub_format = this.$numberFormat(x.total_harga_sub_pembelian, 'IDR')
      }

      vm.totalRows = vm.items.length;
      vm.tableBusy = false;

      //! puskesmas
      let option_puskesmas = await vm.$axios.post("/puskesmas/list");
      vm.option_puskesmas =
        option_puskesmas.data.status == 200
          ? option_puskesmas.data.data.map((x) => {
              return { value: x.puskesmas_id, text: x.nama_puskesmas };
            })
          : [];
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
